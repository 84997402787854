import {AuthGuard, AuthSdkModule} from '@12stz/auth-sdk';
import {provideInterfaceSize} from '@12stz/ui-kit/tokens';
import {provideHttpClient, withInterceptorsFromDi} from '@angular/common/http';
import {importProvidersFrom, provideZoneChangeDetection} from '@angular/core';
import {provideAnimations} from '@angular/platform-browser/animations';
import {provideRouter} from '@angular/router';
import {init} from '@sentry/angular-ivy';
import {
  NgxsConfigModule,
  SentryModule,
  createSentryConfig,
} from './configuration';
import {environment} from './environments';

init(createSentryConfig(environment.production));

const shellRoute = {
  path: '',
  canActivate: [AuthGuard],
  data: {roles: ['access']},
  loadChildren: () =>
    import('./app/shell/shell.module').then((m) => m.ShellModule),
};

const authSdkConfig = {
  authServerUrl: environment.auth,
  clientId: 'feedback',
  loginPath: '',
  returnPath: '',
};

export const providers = [
  importProvidersFrom(
    AuthSdkModule.forRoot(authSdkConfig),
    SentryModule.forRoot(environment.production),
    NgxsConfigModule
  ),
  provideInterfaceSize('small'),
  provideHttpClient(withInterceptorsFromDi()),
  provideAnimations(),
  provideRouter([shellRoute]),
  provideZoneChangeDetection({eventCoalescing: true}),
];
