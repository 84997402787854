import {DOCUMENT} from '@angular/common';
import type {OnInit} from '@angular/core';
import {ChangeDetectionStrategy, Component, inject} from '@angular/core';
import {bootstrapApplication} from '@angular/platform-browser';
import {RouterOutlet} from '@angular/router';
import {environment} from './environments';
import {providers} from './providers';

@Component({
  selector: 'fb-root',
  template: '<router-outlet/>',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [RouterOutlet],
})
export class MainComponent implements OnInit {
  private document = inject(DOCUMENT);

  ngOnInit(): void {
    const preconnectLink = this.document.createElement('link');
    preconnectLink.rel = 'preconnect';
    preconnectLink.href = environment.backend;
    preconnectLink.setAttribute('crossorigin', '');
    this.document.head.append(preconnectLink);
  }
}

bootstrapApplication(MainComponent, {providers}).catch((e) => console.error(e));
