import type {ModuleWithProviders} from '@angular/core';
import {APP_INITIALIZER, ErrorHandler, NgModule} from '@angular/core';
import {Router} from '@angular/router';
import type {BrowserOptions} from '@sentry/angular-ivy';
import {
  TraceService,
  browserTracingIntegration,
  createErrorHandler,
  replayIntegration,
} from '@sentry/angular-ivy';
import {environment} from 'environments';
import {release} from './config.release';

export const createSentryConfig = (enabled: boolean): BrowserOptions => ({
  dsn: 'https://e5a47e109d3b43689faaabb7535bc7b0@sentry.12stz.dev/4',
  enabled,
  environment: environment.name,
  integrations: [
    browserTracingIntegration({
      shouldCreateSpanForRequest: (url: string) =>
        !url.includes(environment.auth),
    }),
    replayIntegration(),
  ],
  release,
  replaysOnErrorSampleRate: 1.0,
  replaysSessionSampleRate: 0.1,
  sendDefaultPii: true,
  tracePropagationTargets: ['localhost', environment.backend, /^\//],
  tracesSampleRate: 0.4,
});

@NgModule()
export class SentryProvidersModuleEmpty {}

@NgModule()
export class SentryModule {
  public static forRoot(enabled: boolean): ModuleWithProviders<SentryModule> {
    return enabled
      ? {
          ngModule: SentryModule,
          providers: [
            {
              provide: ErrorHandler,
              useValue: createErrorHandler({showDialog: true}),
            },
            {provide: TraceService, deps: [Router]},
            {
              provide: APP_INITIALIZER,
              useFactory: () => () => {},
              deps: [TraceService],
              multi: true,
            },
          ],
        }
      : {ngModule: SentryProvidersModuleEmpty, providers: []};
  }
}
