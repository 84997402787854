import {NgModule} from '@angular/core';
import {NgxsReduxDevtoolsPluginModule} from '@ngxs/devtools-plugin';
import {NgxsFormPluginModule} from '@ngxs/form-plugin';
import {NgxsStoragePluginModule} from '@ngxs/storage-plugin';
import {NgxsModule} from '@ngxs/store';
import {environment} from 'environments';
const {name} = environment;

const concatFeature = (slice: string): string => 'feedback.' + slice;
const keys = ['limit', 'fastFilter', 'columnOptions'];
const key = keys.map(concatFeature);

@NgModule({
  imports: [
    NgxsModule.forRoot([]),
    NgxsFormPluginModule.forRoot(),
    NgxsStoragePluginModule.forRoot({key}),
    NgxsReduxDevtoolsPluginModule.forRoot({disabled: name === 'prod'}),
  ],
})
export class NgxsConfigModule {}
